<template>
  <div>
    <ml-container>
      <ml-grid>
        <div class="tw-mt-12 tw-text-center tw-text-black tw-col-start-2 tw-col-span-6">
          <page-title>How to Use Mango & Lola</page-title>
          <div class="tw-mb-8 tw-leading-relaxed tw-text-left">
            <ul class="tw-list-disc tw-space-y-2">
              <li>
                <router-link class="tw-text-red" :to="{ name: 'register'}">Sign up</router-link> for your free private account<br>
                (All your vendors, invoices, & contracts will be in 1 place, & super easy to manage)
              </li>
              <li>
                In your Dashboard enter your wedding date & your wedding details<br>
                Then you are ready to start searching our database for your wedding vendor dream team:
              </li>
              <li>Enter your wedding date</li>
              <li>Enter your wedding location</li>
              <li>Select a vendor category</li>
              <li>Click search</li>
              <li>Filter by price & rating</li>
              <li>Compare available vendors</li>
              <li>Check out vendor offerings (click on “see details”)</li>
              <li>If you find a vendor that is a perfect fit, you can click on “Book”</li>
              <li>Some vendors have “auto-booking”, and some have “booking request”<br>
                *” Auto booking” means your booking will be automatic; “booking request” gives the vendor up to 48 hours to respond, but most respond much quicker, and accept your booking request (like vrbo.com)
              </li>
              <li>If you find a vendor that you like, but do not see what you need, click on the red “Request a Quote” button in their profile, and request a custom quote. The Vendor will respond with a custom quote</li>
              <li>Lastly, if you cannot find a vendor that you need, please send us a note to <ml-link href="mailto:couples@mangolola.com">couples@mangolola.com</ml-link> and our team will reach out and try to help</li>
              <li>In your Dashboard you can track your bookings, quote requests and expenses</li>
              <li>Never miss a payment either as your next payment date is on your invoices</li>
              <li>Every day we are working very hard to onboard the best vendors in the wedding industry</li>
              <li>Enjoy your planning process, and don’t forget to have fun</li>
            </ul>
          </div>
        </div>
      </ml-grid>
    </ml-container>
  </div>
</template>

<script>
import i18n from '@/i18n';
import MlContainer from "@/components/layout/MlContainer";
import PageTitle from "@/components/layout/titles/PageTitle";
import MlGrid from "@/components/layout/MlGrid";
import MlLink from "@/components/layout/MlLink.vue";

export default {
  components: {
    MlLink,
    MlGrid,
    PageTitle,
    MlContainer,
  },
  layout: 'basic',
  metaInfo() {
    return {
      title: 'How to Use Mango & Lola for Couples',
      meta: [
        {
          name: 'description',
          content: i18n.t('description.investors')
        }
      ],
    }
  },
}
</script>
